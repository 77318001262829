/*
全局样式，不要轻易编写全局样式
*/

html, body, #root {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#nprogress .bar {
    z-index: 9999;
}

.ant-form label,
.ant-form-explain {
    font-size: 12px;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon{
    color:#fff !important;
}
/*.ant-form-item-label,*/
/*.ant-form-item-control {*/
/*line-height: 25px;*/
/*}*/

/*.ant-tooltip,*/
/*.ant-modal-mask,*/
/*.ant-modal-wrap,*/
/*.ant-message,*/
/*.ant-menu-submenu-popup,*/
/*.ant-notification {*/
/*z-index: 9999 !important;;*/
/*}*/

/*.ant-modal {*/
/*padding-bottom: 84px !important;*/
/*}*/

/* 只在打印时显示 */
.just-print {
    display: none !important;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #f5f5f5
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5
}

::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 6px
}
.ant-table-empty .ant-table-body{
    overflow:  hidden !important;
}
.anchorBL{
    display: none;
}
@media print {
    body {
        padding: 0 !important;
        background: none;
    }

    /* 打印时不显示 */
    .no-print {
        display: none !important;
    }

    .just-print {
        display: block !important;
    }

    .ant-message {
        display: none !important;
    }

    .ant-modal-mask {
        display: none !important;
    }
}
body .BraftEditor-container.disabled {
    opacity: 1;
    filter: none;
    border: 1px solid #ddd;
}
body .BraftEditor-container.disabled .bf-content {
    height: auto;
}
.different,.different .ant-input,
.different .ant-cascader-picker-label,
.different .ant-input-number-disabled,
.different .ant-select-disabled,
.different .ant-radio-disabled + span{
    color: red !important;
}